<template>
  <div class="wrapper">
    <header>选择发货单</header>
    <!-- 选品名,输数量单价 品名增删改-->
    <div class="split">
      <div style="width:70%;">
        <el-input v-model="inputVal3" @input="hh_inputChange" placeholder="本页搜索" style="margin-left:10px;width:100%;"></el-input>
      </div>
      <div class=" flex-col text-right" style="width:30%;">
        <div >
          <span></span>
        </div>
        <div >
          <span @click="qh_display_Mode" class="text-xl text-amber-500" style="width:30%;">
            <span v-if="displayMode =='表格显示'" class=" text-amber-500"><el-icon ><List /></el-icon>卡片</span>
            <span v-else class=" text-teal-500"><el-icon ><Grid /></el-icon>表格</span>
          </span>
        </div>
      </div>
    </div>
    <!-- 中间滚动区域 -->
    <section>
      <div class="mybody">
        <lzqTable v-if="displayMode =='表格显示'" 
          :tableData_hh_dh="tableData_xm_gx" mytitle="获取整张发货单" 
          :IsSelection="false" RowKey="item6"
          @tableRowClick="tableRowClick($event)"
          @handleSelectionChange="handleSelectionChange($event)"
        >
        </lzqTable>
        <div v-if="displayMode =='卡片显示'">
          <el-card class="box-card" v-for="(item,index) in tableData_xm_gx" :key="index" @click="tableRowClick(item)" style="margin:6px;">
            <template #header>
              <div class="card-header">
                <span class="text-3xl text-amber-500">品名:{{item.item1}}</span>
              </div>
            </template>
            <span v-for="(list,indx) in item" :key="indx" class="text item">
              <span v-if="indx>='item2' && indx<='item3'" class="text-2xl text-teal-500">{{ list }}/</span>
            </span>
          </el-card>
        </div>
      </div>
    </section>
    <!-- 中间选择修改删除弹窗开始 -->
    <el-dialog v-model="dialogFormVisible_xgsctj" width="90%">
      <el-row justify="center">
        <el-col ><span class="mybt"><span class="text-green-600">{{formData.khmc}}</span></span></el-col>
        <el-col ><span class="mybt"><span class="text-red-600">请选择操作:</span></span></el-col>
      </el-row>   
      <el-row justify="center">
        <el-col :span="8"><el-button type="warning" @click="btn_xg_sc_qx('修改')" :loading="myloading">修改{{formData.qxxm}}</el-button></el-col>
        <el-col :span="8"><el-button type="danger" @click="btn_xg_sc_qx('删除')" :loading="myloading">删除{{formData.qxxm}}</el-button></el-col>
        <el-col :span="8"><el-button type="success" @click="btn_xg_sc_qx('取消')" :loading="myloading">取消{{formData.qxxm}}</el-button></el-col>
      </el-row>
    </el-dialog>
    <!-- 中间选择修改删除弹窗结束 -->
    <!-- 底部开始 -->
    <footer>
      <div class="mybutton">
        <el-row class="mb-4">
          <el-button type="warning" @click="goBack">返回</el-button>
          <el-button type="info" @click="toHome">主页</el-button>
        </el-row>
      </div>
    </footer>
    <!-- 底部结束 -->
  </div>
</template>

<script setup>
import myGtdatafun from "../../myapi/myGtdatafun.js";
import dateglobalfun from "../../../dateglobalfun.js"; //日期函数
import data_js from "../../myapi/formData.js"; 
import { ref, reactive, onMounted, watchEffect } from "vue";
import { useRouter, useRoute } from "vue-router";
import { ElMessageBox, ElMessage } from "element-plus";
import lzqTable from '../truecomponents/lzqTable.vue'//引入自定义组件
const tableData_xm_gx = reactive([]);
const tableData_xm_gx_base = reactive([]);
const tableData_ckrbb = reactive([]); //注意这个定义,搞得半死!!
const tableData_ckrbb_base = reactive([]); //注意这个定义,搞得半死!!
const route = useRoute();
const router = useRouter();
const dialogFormVisible_dj = ref(false);
const dialogFormVisible_pm = ref(false);
const dialogFormVisible_xgsctj = ref(false);
const drawer_RtoL_kh = ref(false);
const drawer_RtoL_pm = ref(false);
const myloading = ref(false);
const my_row = ref()
const infoTxt = ref("新增一行");
const displayMode = ref("卡片显示");
const inputVal3 = ref("");

const dh = dateglobalfun.MydateTime();
const zh_options = reactive([]); //注意这个定义,搞得半死!!
const MYheight2 = ref(600);
let formData = ref({})
const oldCztj = ref("");//旧的查找条件

const qh_display_Mode = () =>{//切换显示
  if (displayMode.value=='表格显示') {
    displayMode.value='卡片显示'
  }else{
    displayMode.value='表格显示'
  }
}

onMounted(() => {//页面进入时
  formData.value=data_js.formData_js;
  formData.value.yhmc = localStorage.getItem(myGtdatafun.XMMC + "yhtoken");
  formData.value.xm = localStorage.getItem(myGtdatafun.XMMC + "xm"); //姓名
  formData.value.gjgx = localStorage.getItem(myGtdatafun.XMMC + "gx"); //工价工序
  setTimeout(() => {
    formData.value.cztj = "获取整张发货单";
    khjczl_get();
  }, 100);  
});

const tableRowClick = (row) => {//点击一行跳转
  if (row.item1 == '查无资料') return
  console.log('修改删除',row);
  dialogFormVisible_xgsctj.value=true;  
  formData.value.khmc=row.item1//客户名称
  formData.value.dh=row.item2//单号
};
const btn_xg_sc_qx = (tjstr) => {
  switch (tjstr) {
    case "修改":
      dialogFormVisible_xgsctj.value = false
      router.push({
        path: "dj_fhd_4",
        query: {
          dh:formData.value.dh,
          khmc:formData.value.khmc,
        },
      });
    break;
    case "删除":
      dialogFormVisible_xgsctj.value = false
      ElMessageBox.confirm(
        "[" + formData.value.dh  + "]确认删除吗?",
        "确认删除吗?",
        {confirmButtonText: "删除",cancelButtonText: "取消",type: "error",}
      )
        .then(() => {
          formData.value.cztj = "删除整张发货单";
          khjczl_get()
        })
        .catch(() => {console.log('取消删除一行');dialogFormVisible_xgsctj.value = false});
    break;
    case "取消":
      dialogFormVisible_xgsctj.value = false
    break;
  }  
}

const khjczl_get = async () => {//提交到数据库
  myGtdatafun
    .MygettableData("html_dysj_ZSG.php", formData.value) 
    .then(function (response) {
      myloading.value = false
      if (response.length <= 0) { return }
      switch (formData.value.cztj) {
        case "获取整张发货单":
        case "删除整张发货单":
          tableData_xm_gx.length = 0;
          tableData_xm_gx_base.length = 0;
          response.forEach((item) => {
            tableData_xm_gx.push(item);
            tableData_xm_gx_base.push(item);
          });
          break;
      }
    })
    .catch(function (error) {
    });
};

const goBack = () => {
  router.go(-1)
};
const toHome = () => {
  router.push("/");
};

const hh_inputChange = async (e) => {//本页面筛选
  tableData_xm_gx.length = 0; //先清空显示的数组
  tableData_xm_gx_base.forEach((item, index) => {
    if (item.item1.indexOf(inputVal3.value.toUpperCase()) >= 0 ||
      item.item2.indexOf(inputVal3.value.toUpperCase()) >= 0 ||
      item.item3.indexOf(inputVal3.value) >= 0
    ) {
      tableData_xm_gx.push(item); //再把选到的加入显示数组
    }
  });
};

</script>
<style scoped>
html,
body {
  height: 100%;
}

.wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

header,
footer {
  height: 40px;
  line-height: 40px;
  background-color: #d8d8d8;
  text-align: center;
}

section {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  width: 100%;
  overflow: auto;
  /* winphone8和android4+ */
  -webkit-overflow-scrolling: touch;
  /* ios5+ */
}

.mybutton {
  display: flex;
  justify-content: center;
}

:deep(.el-collapse-item__header) {
  color: red;
  font-weight: 600;
  margin-left: 10px;
}

:deep(.el-form-item__label) {
  color: #000;
  font-size: 12px;
  font-weight: 600;
  margin-left: 10px;
}

:deep(.el-collapse-item__content) {
  color: #000;
  font-size: 16px;
  font-weight: 600;
  margin-left: 10px;
}

.mybt {
  color: #000;
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  margin: 0 auto;
}

:deep(.el-carousel__container) {
  height: 380px;
}

img {
  height: 380px;
}

.split {
  margin: 6px; margin-bottom: 10px; display: flex;
  justify-content: space-between; align-items: center;
}
</style>